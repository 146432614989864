import React, { FC } from "react";
import { PageProps, Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

const NotFoundPage: FC<PageProps> = ({ location }) => {

    return (
        <Layout location={location}>
            <Seo title="404: Not Found" />

            <div className="text-center p-4 border-b border-gray-50">
                <h2 className="text-2xl">404: Not Found</h2>
                <p>ここには何もありません。。。</p>

            </div>

            <div className="text-center py-4">
                <ul className="flex text-sm justify-center">
                    <li><Link className="inline-block" to="/"><Button>トップページ</Button></Link></li>
                    <li><a    className="inline-block" href="https://kyoya0819.com"><Button>ホームページ</Button></a></li>
                </ul>
            </div>
        </Layout>
    );
};
export default NotFoundPage;


const Button: FC = ({ children }) => {
    return (
        <div
            className="inline-block mb-2 mx-1 p-2 rounded-md bg-gray-50 hover:bg-gray-100 active:bg-gray-200 transition-colors"
        >
            { children }
        </div>
    );
};